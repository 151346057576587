/*
 * @Author: wangqs 
 * @description 全部待还
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-09-22 18:03:08
 */
<template>
  <div class="page-box box">
    <!-- 订单列表 -->
    <div class="list-box box">
      <template v-if="list && list.length > 0">
        <repay-item
          ref="repayItem"
          :list-data="list"
          @getAmount="getAmount"
        ></repay-item>
      </template>
      <div
        v-else
        style="background: #fff;"
        class="box"
      >
        <van-empty
        
          description="暂无明细"
        />
      </div>
    </div>
    <!-- <van-button
      class="pay-btn"
      color="#51B464"
      round
      type="info"
      @click="payBtn"
    >
      全部还款
    </van-button> -->
    <!-- 按钮 -->
    <!-- <div class="btn-box box">
      <van-button
        class="btn"
        color="#51B464"
        plain
        type="primary"
      >
        还款选中部分
      </van-button>
      <van-button
        class="btn"
        color="#51B464"
        type="info"
        @click="repayBtnAll"
      >
        还款全部
      </van-button>
    </div> -->
    <van-submit-bar
      :price="selectObj.amount * 100"
      button-text="去还款"
      button-color="#51B464"
      @submit="onSubmit"
    >
      <van-checkbox
        v-model="selectObj.selectAll"
        @change="radioChange"
      >
        全选
      </van-checkbox>
    </van-submit-bar>
  </div>
</template>

<script>
import RepayItem from './components/wait-repay-item.vue'
export default {
  name: 'Test',
  components: {
    RepayItem
  },
  data () {
    return {
      list: [],
      amount: 0,
      overdue: '',
      selectObj: {
        amount: 0,
        selectAll: false,
        arr: []
      }
    }
  },
  computed: {
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      let res =  await this.$http.repayApi.repayBillAll({})
      console.log(res, 'res==')
      if (res.code === 200) {
        // this.amount = res.msg.amount || 0
        // this.overdue = res.msg.overdue || 0
        let list = res.msg || []
        for (let i of list) {
          this.$set(i, 'show', false)
        }
        this.list = list
      }
    },
    payBtn () {
    },
    repayBtnAll () {
      this.$router.push('/user/allRepay?type=wait&amount=' + this.selectObj.amount)
    },
    onSubmit () {
       if (this.selectObj.arr && this.selectObj.arr.length > 0) {
        let orderNoList = []
        let repayWay = '2'
        for (let i of this.selectObj.arr) {
          orderNoList.push(i.orderNo)
          if (i.repayWay == '1') {
            repayWay = '1'
          }
        }
        console.log(this.selectObj, 'obj')
        window.localStorage.setItem('repayOrderList', JSON.stringify(orderNoList))
        this.$router.push('/user/allRepay?type=wait&amount=' + this.selectObj.amount + '&repayWay=' + repayWay)
      } else {
        this.$toast.fail('请选择订单')
      }
    },
    radioChange (e) {
      console.log(e)
      // if (e == undefined) {
      //   return
      // }
      if (e === true) {
        this.$refs.repayItem.selectAll()
      } else if (e === false) {
        this.$refs.repayItem.clearSelect()
      }
    },
    radioClick (e) {
      console.log(e)
    },
    getAmount (data) {
      this.selectObj.arr = data.arr
      if (this.selectObj.arr.length === this.list.length) {
        this.selectObj.selectAll = true
      }
      this.selectObj.amount = data.amount
      console.log(data, 'data')
      data.arr.length == this.list.length ? this.selectObj.selectAll = true : ''
      data.arr.length == 0 ? this.selectObj.selectAll = false : ''
    }

  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 70px;
  .pay-btn {
    position: fixed;
    left: 50%;
    margin-left: - 173px;
    bottom: 20px;
    width: 346px;
    display: block;
  }
  .btn-box {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 6px;
    text-align: center;
    background: #fff;
    z-index: 9;
    .btn {
      width: 166px;
      margin: 0 5px;
    }
  }
}
</style>
