<template>
  <div class="box list-item">
    <div
      v-for="(item, index) in listData"
      :key="index"
      class="item-box box"
    >
      <van-checkbox-group
        v-model="result"
        class="radio-box"
        @change="changeSelect"
      >
        <van-checkbox :name="index"></van-checkbox>
      </van-checkbox-group>
      <div class="order-title">
        <div
          class="t-left ellipsis"
          @click="showOrderNo(item)"
        >
          订单编号：{{ item.orderNo || '--' }}
        </div>
        <div class="t-right">
          还款日 {{ item.repayTime && item.repayTime.substring(0,10) || '--' }}
        </div>
      </div>

      <div class="text-a box">
        <div class="text-a-a">
          {{ item.courseName || '--' }}
        </div>
        <div class="text-a-b">
          学员：{{ item.childName || '--' }}
        </div>
      </div>
      <div class="text-b">
        {{ item.schoolName || '--' }}
      </div>
      <div class="text-c box">
        <div class="text-c-a">
          待还金额
        </div>

        <div class="price-text">
          ￥{{ parseFloat(item.totalAmount || 0).toFixed(2) }}
        </div>
      </div>

      <div
        class="detail-box box"
        :class="{'detail-active-box': item.show, 'detail-active-box-b': item.show && item.overdueTime}"
      >
        <div class="text-box">
          <div class="t-left">
            待还本金
          </div>
          <div class="t-right">
            ￥{{ parseFloat(item.amount || 0).toFixed(2) }}
          </div>
        </div>

        <div class="text-box">
          <div class="t-left">
            全部待还手续费
          </div>
          <div class="t-right">
            ￥{{ parseFloat(item.serviceFee || 0).toFixed(2) }}
          </div>
        </div>

        <div class="text-box">
          <div class="t-left">
            待还账户管理费
          </div>
          <div class="t-right">
            ￥{{ parseFloat(item.channelFee || 0).toFixed(2) }}
          </div>
        </div>

        <div class="text-box">
          <div class="t-left">
            待还期数
          </div>
          <div class="t-right">
            {{ item.period || '' }}
          </div>
        </div>

        <div class="text-box">
          <div class="t-left">
            提前还款手续费
          </div>
          <div class="t-right">
            {{ item.earlyFee || '' }}
          </div>
        </div>

        <div
          v-if="item.overdueTime"
          class="text-box"
        >
          <div class="t-left">
            逾期天数
          </div>
          <div class="t-right">
            {{ item.overdueTime || 0 }}天
          </div>
        </div>

        <div class="text-box">
          <div class="t-left">
            逾期费用
          </div>
          <div class="t-right">
            ￥{{ parseFloat(item.overdueFee || 0).toFixed(2) }}
          </div>
        </div>
      </div>

      <!-- 按钮 -->
      <div
        class="detail-btn box"
        @click="item.show = !item.show"
      >
        明细
        <van-icon
          v-show="!item.show"
          name="arrow-down"
        />
        <van-icon
          v-show="item.show"
          name="arrow-up"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitRepayItem',
  components: {
  },
  props: {
    listData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      result: []
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    selectAll () {
      console.log('进入all')
      let arr = []
      for (let i = 0; i < this.listData.length; i++) {
        arr.push(i)
      }
      this.result = arr
      this.$nextTick(() => {
        this.getSelectItem()
      })
    },
    clearSelect () {
      this.result = []
      this.$nextTick(() => {
        this.getSelectItem()
      })
    },
    getSelectItem () {
      let arr = [],amount = 0,lastAmount = 0
      console.log('进入', this.result)
      for (let i = 0; i < this.result.length; i++) { 
        arr.push(this.listData[this.result[i]])
        amount += this.listData[this.result[i]].totalAmount || 0
        lastAmount += this.listData[this.result[i]].totalAmount || 0
        lastAmount -= this.listData[this.result[i]].serviceFee || 0
      }
      this.$store.commit('setAllLastAmount', lastAmount)
      console.log(lastAmount, 'lastAmount')
      let selectAll = arr.length === 0
      let data = {arr, amount, selectAll}
      this.$emit('getAmount', data)
      return data
    },
    changeSelect (e) {
      this.getSelectItem()
    },
    showOrderNo (item) {
      if (item.orderNo) {
        this.$toast(item.orderNo)
      }
    } 
  }
}
</script>

<style lang="scss" scoped>
@import './item.scss';
.detail-active-box {
  height: 150px!important;
}
.detail-active-box-b {
  height: 170px!important;
}
</style>
